// -----------------------------
// CB Photo Gallery
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/button.scss";
@import "../mixins/photo-gallery.scss";

$thumbMarginLR: 24px;
$thumbMarginTB: 48px;

// thumbnails
.cb-photo-gallery {
  ul {
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    margin-left: -#{$thumbMarginLR};
    margin-top: -#{$thumbMarginTB};

    li {
      list-style: none;
      margin: 0;
      flex-shrink: 0;
      margin-top: $thumbMarginTB;
      margin-left: $thumbMarginLR;

      .cb-photo-thumb {
        // display: flex;

        img {
          &:not([data-cb-lazy]) {
            width: 100%;
            height: auto;
            max-height: 100%;
          }
          &.cb-active-effect {
            width: 100%;
            height: auto;
            max-height: 100%;
          }
          overflow: hidden;
          opacity: 1;
          transition: opacity 200ms;
          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }
        + .cb-photo-thumb-info {
          margin-top: 16px;
        }
      }

      .cb-photo-thumb-info {
        h2,
        .cb-h2,
        h3,
        .cb-h3,
        h4,
        .cb-h4,
        h5,
        .cb-h5 {
          color: $font-color-base;
          font-weight: 500;
          font-size: 1.3125rem; //21
          line-height: 1.1428571428571428em; //24

          @include media-breakpoint-down("md", $grid-breakpoints) {
            font-size: 1.1875rem;
            line-height: 1.263157894736842em;
          }

          // md and down 19
          margin: 0;
          + p {
            margin-top: 4px;
          }
        }

        p {
          display: none;
          color: $font-color-base;
          &:first-of-type {
            display: block;
            margin-bottom: 0;
          }
        }
      }

      a {
        color: $font-color-base;
        text-decoration: none;

        &:hover,
        &:active,
        &.cb-focus {
          * {
            color: $font-color-base;
            text-decoration: underline;
          }
        }

        &.cb-focus {
          text-decoration: underline;
          cursor: pointer;

          img {
            opacity: 0.7;
          }
        }
      }

      .cb-photo-details-info {
        display: none;
        visibility: hidden;
      }
    }
    &.cb-photo-gallery-grid {
      margin-right: -12px;
      margin-left: -12px;

      li {
        margin-top: $thumbMarginTB;
        margin-left: 0;
      }
    }

    // ------ item width calculation
    &.cb-photo-gallery-5 {
      // 5 items
      @include cb-photo-gallery-item(5, 8, $thumbMarginLR);
    }

    &.cb-photo-gallery-4 {
      // 4 items
      @include cb-photo-gallery-item(4, 6, $thumbMarginLR);
    }

    &.cb-photo-gallery-3 {
      // 3 items
      @include cb-photo-gallery-item(3, 4, $thumbMarginLR);
    }

    &.cb-photo-gallery-2 {
      // 2 items
      @include cb-photo-gallery-item(2, 2, $thumbMarginLR);

      .cb-photo-thumb {
        height: 144px;
      }
    }

    &.cb-photo-gallery-1 {
      // 1 items
      li {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .cb-photo-thumb {
          height: 144px;
        }
      }
    }
    @include media-breakpoint-only("xs", $grid-breakpoints) {
      flex-direction: column;
      padding: 0 0 0 24px;
      li {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
        .cb-photo-thumb {
          height: auto !important;
          img {
            width: 100%;
            max-width: auto;
          }
        }
      }
    }
  }
  &.cb-no-desc {
    ul {
      margin-top: -#{$thumbMarginLR};
      li {
        margin-top: $thumbMarginLR;
      }
    }
  }
}

// ----------- modal
.cb-photo-gallery-modal {
  .cb-modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    .cb-modal-content {
      height: calc(100vh - 141px) !important;
      margin-top: 141px;
      padding-bottom: 141px;
      background-color: $black1;
    }
    .cb-gallery-header {
      width: 100%;
      height: 141px;
      background-color: $black1;
      color: $white;
      padding: 0 41px;
      margin-bottom: 0;
      position: absolute!important;
      top: 0;
      left: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cb-gallery-counter {
        &.cb-btn-3 {
          width: calc(100% - ((3 * 32px) + 32px));
          padding-left: calc((3 * 32px) + 32px);
        }
        &.cb-btn-2 {
          width: calc(100% - ((2 * 32px) + 16px));
          padding-left: calc((2 * 32px) + 16px);
        }
        &.cb-btn-1 {
          width: calc(100% - 32px);
          padding-left: 32px;
        }
        text-align: center;
        .cb-count-info {
          display: inline-flex;
          font-size: $font-size-small;
          margin-bottom: 0;
          span {
            &:nth-of-type(2) {
              margin-left: 6px;
              margin-right: 6px;
              display: inline-block;
              width: 10px;
            }
          }
        }
      }

      .cb-gallery-controls {
        .cb-btn {
          position: relative;

          @include close();
          + .cb-btn {
            margin-left: 16px;
          }
        }
      }
    }

    .cb-gallery-container {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: $black1;
      @include media-breakpoint-down("sm", $grid-breakpoints) {
        height: calc(100vh - 71px) !important;
        overflow-y: auto;
      }

      // left/right arrow
      .cb-gallery-navigation {
        width: 144px;
        transition: width $transition-time-s ease-in-out;
        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }

        @include media-breakpoint-only("md", $grid-breakpoints) {
          width: 96px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 3rem;
          width: 0.5em;
          height: 1em;
          @include media-breakpoint-down("sm", $grid-breakpoints) {
            font-size: 1.5rem;
          }
          opacity: 0.5;
          color: $white;
          transition: opacity 300ms;
          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }

        &:hover,
        &.hover {
          cursor: pointer;
          span {
            opacity: 1;
          }
        }
      }

      .cb-gallery-content {
        position: relative;
        display: flex;
        width: calc(100% - 288px);
        @include media-breakpoint-only("md", $grid-breakpoints) {
          width: calc(100% - 192px);
        }

        @include media-breakpoint-up("md", $grid-breakpoints) {
          justify-content: center;
        }

        .cb-gallery-image {
          width: calc(100% * (2 / 3));
          background-color: $white;
          height: inherit;
          position: relative;
          transition: width $transition-time-s ease-in-out;
          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }

          .cb-gallery-image-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              transition: opacity $transition-time-s ease-in-out;
              @media (prefers-reduced-motion: reduce) {
                transition: none;
              }
            }
            &.cb-has-fullscreen {
              img {
                &:hover {
                  cursor: zoom-in;
                }
              }
            }
            &.cb-hide-effect {
              img {
                opacity: 0;
              }
            }
            &.cb-active-effect {
              img {
                opacity: 1;
              }
            }
          }
        }

        @include media-breakpoint-up("md", $grid-breakpoints) {
          &.cb-image-padding {
            &:not(.cb-no-info) {
              .cb-gallery-image {
                .cb-gallery-image-container {
                  padding: 24px 0 24px 24px;
                }
              }
            }

            &.cb-no-info {
              .cb-gallery-image {
                background: transparent;
              }
            }
          }
        }

        .cb-info-container {
          display: flex;
          flex-direction: column;
          width: calc(100% * (1 / 3));
          background-color: $white;
          padding: 48px;
          @include media-breakpoint-only("md", $grid-breakpoints) {
            padding: 24px;
          }
          min-height: 96px;
          .cb-gallery-des {
            width: 100%;
            overflow-y: auto;
            h4,
            h5 {
              font-size: 1rem;
              line-height: 1.5em;
              margin: 0;
            }

            .cb-main-title {
              font-size: 1.188rem;
              line-height: 30px;
              margin: 0;
              padding: 0;
            }

            .cb-main-info {
              color: $font-size-base;
              margin: 0;
              padding: 0;
            }

            .cb-sub-title {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1em;
              margin: 0.75em 0 0.375em;
              margin-top: 30px;
            }
          }
          &.cb-no-info {
            display: none;
          }
        }
      }
    }

    // Mobile & tablet
    @include media-breakpoint-down("sm", $grid-breakpoints) {
      .cb-gallery-header {
        width: 100%;
        height: 71px;
        padding: 0 24px;
        .cb-gallery-counter {
          text-align: left;
          display: flex;
          padding-left: 0 !important;
        }
      }

      .cb-modal-content {
        position: relative;
        height: calc(100vh - 71px) !important;
        margin-top: 71px;
        padding-bottom: 0;
        .cb-gallery-container {
          height: auto;
          .cb-gallery-navigation {
            width: 24px;
          }
          .cb-gallery-content {
            width: calc(100% - 48px);
            justify-content: top;
            height: 100%;
            overflow-y: auto;
            flex-direction: column;
            .cb-gallery-image {
              width: 100%;
              position: relative;
              height: auto;
              .cb-gallery-image-container {
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .cb-gallery-hit {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
            .cb-info-container {
              width: 100%;
              padding: 24px;
              min-height: fit-content;
              flex: 1 1 auto;
            }
          }
        }
      }
    }
  }

  &.cb-no-desc {
    .cb-modal-container {
      .cb-gallery-container {
        .cb-gallery-content {
          width: 100%;
          .cb-gallery-image {
            width: 100%;
            background-color: transparent;
          }
          .cb-info-container {
            display: none;
          }

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            .cb-gallery-image {
              .cb-gallery-image-container {
                height: calc(100vh - 71px);
              }
            }
          }
        }
      }
    }
  }

  &.cb-single-img {
    .cb-modal-container {
      .cb-gallery-header {
        .cb-gallery-controls {
          margin-left: auto;
          .cb-btn-play {
            display: none;
          }
        }
        .cb-gallery-counter {
          display: none;
        }
      }
      .cb-gallery-container {
        .cb-gallery-content {
          width: 100%;

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            .cb-gallery-image {
              &::after {
                padding-top: calc(100% - 41px);
              }
            }
          }
        }
      }
    }
  }

  &.cb-photo-full {
    // cursor: zoom-out !important;
    .cb-modal-container {
      .cb-gallery-container {
        .cb-gallery-content {
          .cb-gallery-image {
            .cb-gallery-image-container {
              &.cb-has-fullscreen {
                img {
                  &:hover {
                    cursor: zoom-out !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
