// -----------------------------
// CB Card
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/link.scss";

.cb-card {
  background-color: $white;
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.5em;

  a:not(.cb-btn) {
    @include black-link();
  }

  &.cb-white-color {
    a {
      @include white-link();
    }
  }

  &:not(.cb-card-link) {
    padding: 24px;

    .cb-text-icon-overline {
      .cb-glyph {
        &.cb-glyph-circular {
          &::after {
            background-color: $gray1;
          }
        }
      }
      .cb-icon {
        &.cb-icon-circular {
          &::after {
            background-color: $gray1;
          }
        }
      }
    }
  }

  .cb-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .cb-card-title {
    font-size: 1.3125rem; //21
    line-height: 1.5238095238095237em; //32
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 8px;
    color: $font-color-base;
    + .cb-card-label {
      margin-top: 8px;
    }
  }

  .cb-card-desc {
    font-size: 0.875rem;
    line-height: 1.7142857142857142em;
    font-weight: 300;
    color: $font-color-base;
    + .cb-card-label {
      margin-top: 16px;
    }
  }
  .cb-date-time-tags * {
    color: $font-color-base;
  }

  .cb-card-title-large {
    @include roboto-black();
    font-size: 2rem; //32
    line-height: 1.125em; //36
    color: $font-color-base;
  }

  .cb-card-footer {
    margin-top: auto;

    // First
    .cb-btn:first-child:nth-last-child(2):first-of-type,
    .cb-btn:first-child:nth-last-child(2)~.cb-btn:first-of-type,
    // Second
    .cb-btn:first-child:nth-last-child(2):last-of-type,
    .cb-btn:first-child:nth-last-child(2)~.cb-btn:last-of-type {
      width: calc(50% - 16px);
      min-width: min-content;
    }
  }

  .cb-card-action {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: flex-end;
    margin-top: 24px;

    .cb-glyph,
    .cb-icon {
      align-self: flex-end;
      line-height: 24px;
    }

    .cb-meta-tags {
      min-width: fit-content;
      .cb-glyph,
      .cb-icon {
        align-self: auto;
      }
    }

    span:first-child:last-child {
      margin-left: auto;
    }
  }

  .cb-card-media-content {
    min-height: 196px;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 24px;
    position: relative;

    .cb-card-media-img {
      position: absolute;
      z-index: 1;
      height: 196px;
      width: 100%;
      background-position: center;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  // Single Link Cards
  &.cb-card-link {
    > a {
      padding: 24px;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;

        .cb-card-title,
        .cb-card-title-large {
          text-decoration: underline;
        }

        @for $index from 1 through 6 {
          h#{$index} {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.cb-card-title-link {
    position: relative;
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
    &.cb-card-media,
    &.cb-card-document {
      a {
        &:after {
          z-index: 3;
        }
      }
    }
  }

  // ------------------------------------ CARD TYPES

  &.cb-card-event {
    &.cb-super-event {
      .cb-card-header {
        flex-wrap: wrap;
        margin-left: -24px;
        margin-top: -16px;
        .cb-date-time-tags {
          margin-top: 16px;
          margin-left: 24px;
        }
        .cb-super-event-name {
          margin-top: 16px;
          margin-left: 24px;
          padding: 0 8px;
          box-shadow: 0 0 1px 1px $gray3 inset;
          color: $black1;
          font-size: $font-size-small;
          line-height: $line-height-small;
          white-space: nowrap;
        }
      }
    }
    .cb-card-content {
      .cb-card-action {
        flex-wrap: wrap;

        span:not(.cb-text-super-event):first-child:last-child {
          margin-left: auto;
        }

        .cb-text-super-event {
          margin-top: 24px;
          margin-right: 24px;
        }

        .cb-btn.cb-btn-primary:first-child:last-child {
          margin-left: auto;
        }
      }
    }
  }

  &.cb-card-cta {
    padding-top: 48px;
    padding-bottom: 48px;
    .cb-card-header {
      .cb-glyph-content {
        color: $black1;
        font-size: 2rem;
        background-color: $white;
        border-radius: 100%;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.08);
        width: 72px;
        height: 72px;
        position: relative;
        &::before,
        &::after {
          top: 5px;
          left: 5px;
        }
      }
    }
    .cb-card-action {
      margin-top: 24px;
    }
  }

  &.cb-card-promo {
    border-left: 24px solid transparent;
    &.cb-no-border {
      border-left: 0;
    }
    .cb-card-header {
      flex-direction: column;
      margin-bottom: 0;
      .cb-icon + .cb-card-title {
        margin-top: 16px;
      }
      .cb-card-title {
        margin-bottom: 0;
      }
    }
    .cb-card-content {
      .cb-card-desc {
        margin-top: 16px;
      }
      .cb-card-action {
        margin-top: 0;
        .cb-meta-tags {
          margin-top: 16px;
          &.cb-text-author {
            &::before {
              content: "―";
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
}
// ------------------------------------ CARD BAND
.cb-band {
  &.cb-band-hero-card {
    .cb-card {
      @include media-breakpoint-up("md", $grid-breakpoints) {
        padding: 32px;
      }
      @include media-breakpoint-up("xl", $grid-breakpoints) {
        padding: 48px;
      }
      .cb-card-action {
        align-items: center;
        .cb-btn-row {
          margin-top: -16px;
        }
      }
    }
  }
}

// ------------------------------------ Horizontal List
[class*="cb-card-list-horizontal"],
.cb-card-list-horizontal-24 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-top: -24px;
  width: calc(100% + 24px);

  // Mobile
  @include media-breakpoint-down("xs", $grid-breakpoints) {
    flex-direction: column;
  }

  .cb-card {
    margin-top: 24px;
    margin-left: 24px;

    max-width: calc(25% - 24px);
    min-width: 336px;
    width: 100%;

    .cb-card-content {
      flex: 1 1 auto !important;
      display: flex;
      flex-direction: column;
    }

    // Tablet
    @include media-breakpoint-down("sm", $grid-breakpoints) {
      max-width: calc(50% - 24px);
      min-width: auto;
    }

    // Mobile
    @include media-breakpoint-only("xs", $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 24px);
    }
  }

  // Flexing
  .cb-card {
    > a {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .cb-card-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .cb-card-action {
        flex: 1;
        align-items: flex-end;

        .cb-glyph,
        .cb-icon {
          line-height: 24px;
        }
      }
    }
  }

  &.cb-box-shadow {
    box-shadow: none;

    .cb-card {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    }
  }
}
.cb-card-list-horizontal-32 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -32px;
  width: calc(100% + 32px);

  .cb-card {
    margin-top: 32px;
    margin-left: 32px;

    max-width: calc(33% - 32px);
    min-width: 288px;
    width: 100%;

    // Tablet
    @include media-breakpoint-only("sm", $grid-breakpoints) {
      max-width: calc(50% - 32px);
    }

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 32px);
    }
  }
}
.cb-card-list-horizontal-48 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -48px;
  margin-top: -48px;
  width: calc(100% + 48px);

  .cb-card {
    margin-top: 48px;
    margin-left: 48px;

    max-width: calc(33% - 48px);
    min-width: 276px;
    width: 100%;

    // Tablet
    @include media-breakpoint-only("sm", $grid-breakpoints) {
      max-width: calc(50% - 48px);
    }
    @include media-breakpoint-only("xs", $grid-breakpoints) {
      width: 100%;
      max-width: calc(100% - 48px);
    }
  }
}

// ------------------------------------ Vertical List
.cb-card-list-vertical {
  .cb-card {
    border: 0;
    border-top: 1px solid $border-color;
    box-shadow: none;
    border-radius: 0;
    text-align: left;
    max-width: 100%;
    .cb-card-header {
      margin-bottom: 8px;
    }
  }
}

// ------------------------------------ Elective
.cb-card-list-horizontal-2,
.cb-card-list-horizontal-elective {
  @include media-breakpoint-down("xs", $grid-breakpoints) {
    margin-left: -24px;
  }

  .cb-card {
    width: calc(50% - 24px);
    max-width: calc(50% - 24px);
    min-width: auto;

    @include media-breakpoint-down("xs", $grid-breakpoints) {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      margin-left: 0;
      width: calc(100% + 24px);
      min-width: calc(100% + 24px);
    }
  }
}

// ------------------------------------ Custom Layout
.cb-card-custom-col {
  .cb-card {
    .cb-card-content {
      flex: 1 1 auto !important;
      display: flex;
      flex-direction: column;
    }
    &.cb-card-link {
      > a {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

// ------------------------------------ SAFARI Issue
// [class*="safari"] {
//   .cb-date-time-tags {
//     p {
//       line-height: 20px !important;
//       padding-top: 4px !important;
//       &:first-of-type {
//         &::after {
//           top: 0 !important;
//         }
//       }
//     }
//   }
// }

// ------------------------------------ Identity Card
.cb-card {
  &.cb-identity-card {
    align-items: center;
    padding: 32px;

    border-radius: 16px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    .cb-identity-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 375px;
      min-width: 375px;
      p {
        width: 264px;
      }
      .cb-btn-primary {
        width: 200px;
        color: #fff;
      }
      @include media-breakpoint-only("xs", $grid-breakpoints) {
        width: auto;
        min-width: auto;
      }
      @media (max-width: 380px) {
        p,
        .cb-btn-primary {
          width: auto;
        }
      }
    }
  }
}

// ------------------------------------ Keyboard

[data-cb-input-m="keyboard"] {
  .cb-card-title-link {
    a {
      &:focus {
        box-shadow: none;
        outline: none;
        &::after {
          border-radius: 2px;
          box-shadow: inset 0 0 0 2px rgb(0, 95, 204);
        }
      }
    }
  }
}
