// -----------------------------
// CB Tooltip
// -----------------------------
 
 @import "apricot-variable.scss";

.cb-tooltip {
  position: absolute;
  background: $tooltip-bg;
  color: $tooltip-color;
  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;  
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  text-align: center;
  z-index: $zindex-tooltip;
  opacity: 0;
  transition: opacity $transition-time-l ease-in-out;
  visibility: hidden;

  .cb-tooltip-inner {
    font-size: $tooltip-font-size;
    padding: 12px;
  }

	&.cb-tooltip-light{
    background-color: $white;
    color: $black1;
  }

  &[data-popper-placement^="top"],
  &[data-popper-placement^="bottom"],
  &[data-popper-placement^="right"],
  &[data-popper-placement^="left"] {
    opacity: 1;
  }

  &[aria-hidden="true"]:not(.cb-repetitive-label) {
    display: none;
  }
}
