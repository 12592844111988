// -----------------------------
// CB Form
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/utils.scss";
@import "../mixins/glyph.scss";
@import "../mixins/form.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/link.scss";

// ------------------------------------ INPUT, TEXTAREA

.cb-input {
  > div {
    width: 100%;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    line-height: 1.5em;

    border: 1px solid $gray4;
    background-color: $white;

    padding: 11px 15px;

    border-radius: $border-radius-input;

    &::placeholder {
      color: $input-placeholder;
      font-size: 1rem;
      line-height: 1.5;
    }

    @include border-box();

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: 0;
        cursor: default;
        border: 1px solid $input-focus;
        box-shadow: inset 0px 0px 1px 0.5px rgba(48, 73, 207, 1);
      }
    }

    &:disabled {
      color: $gray2;
      cursor: not-allowed;
      -webkit-text-fill-color: $gray2; /* Override iOS / Android font color change */
      -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    }
  }

  &.cb-disabled {
    label,
    .cb-glyph,
    .cb-icon {
      color: $gray2 !important;
    }
  }

  .cb-glyph,
  .cb-icon {
    font-size: 1em;
  }

  .cb-input-icon-right,
  .cb-input-icon-left {
    position: relative;

    .cb-glyph,
    .cb-icon {
      position: absolute;
      color: $gray1;
      top: 36px;
      padding: 4px 0;
    }
  }

  .cb-input-icon-left {
    input {
      padding-left: 39px;
    }

    .cb-glyph,
    .cb-icon {
      left: 16px;
    }
  }

  .cb-input-icon-right {
    input {
      padding-right: 40px;
    }

    .cb-glyph,
    .cb-icon {
      right: 16px;
    }
  }

  &.cb-input-no-label {
    .cb-input-icon-left,
    .cb-input-icon-right {
      .cb-glyph,
      .cb-icon {
        top: 12px;
      }
    }
  }

  // ------------------------------------ BUTTON INPUT
  &.cb-btn-input {
    position: relative;

    input {
      padding-right: 48px;
      z-index: 1;
    }

    .cb-btn {
      border: transparent;
      font-size: 1rem;
      line-height: 1em;
      width: 2em;
      height: 2em;
      padding: 0;

      position: absolute;
      right: 8px;
      top: 32px;
      z-index: 2;

      .cb-glyph,
      .cb-icon {
        position: relative;
        top: 0;
        left: 0;
        color: $gray1;
        transition: color $transition-time-l ease;
        padding: 0;
      }

      &:hover {
        background: transparent;
        box-shadow: none;
      }
      &:disabled {
        &:hover {
          background-color: $gray5;
          border-color: $gray5;
        }
      }
      &:focus {
        .cb-glyph,
        .cb-icon {
          color: $blue5;
        }

        box-shadow: none;
        background: transparent;
      }
    }
    input[aria-label] + .cb-btn {
      top: 8px;
    }

    &.cb-password-input {
      .cb-btn {
        &:focus {
          .cb-glyph,
          .cb-icon {
            color: $black1;
          }
        }
      }
    }

    &.cb-input-condensed,
    &.cb-condensed {
      .cb-btn {
        width: 1.5em;
        height: 1.5em;

        .cb-glyph,
        .cb-icon {
          font-size: $font-size-small !important;
        }
      }
    }

    &.cb-input-no-label {
      .cb-btn {
        top: 8px;
      }
    }

    &.cb-disabled {
      .cb-btn {
        background: transparent;
        .cb-glyph,
        .cb-icon {
          color: $gray2;
        }
      }
    }
  }

  // ------------------------------------ CONDENSED
  &.cb-input-condensed,
  &.cb-condensed {
    font-size: $font-size-small;
    line-height: 1.7142857142857142em;

    input,
    textarea {
      padding: 7px 15px;
      &::placeholder {
        font-size: $font-size-small;
        line-height: 1.7142857142857142em;
      }
    }

    .cb-input-icon-right,
    .cb-input-icon-left {
      .cb-glyph,
      .cb-icon {
        top: 33px;
      }
    }

    .cb-input-icon-left {
      input {
        padding-left: 39px;
      }

      .cb-glyph,
      .cb-icon {
        left: 16px;
      }
    }

    .cb-input-icon-right {
      input {
        padding-right: 40px;
      }

      .cb-glyph,
      .cb-icon {
        right: 16px;
      }
    }

    &.cb-input-no-label {
      .cb-input-icon-left,
      .cb-input-icon-right {
        .cb-glyph,
        .cb-icon {
          top: 10px;
        }
      }
    }

    &.cb-btn-input {
      input {
        padding-right: 38px;
      }

      .cb-btn {
        .cb-glyph,
        .cb-icon {
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// ------------------------------------ HELPER TEXT
.cb-input-helper {
  color: $gray1;
  font-size: $font-size-xsmall;
  line-height: 1.3333333333333333em;
  margin-top: 8px;
  &.cb-validation-error {
    color: $red1;
  }
}
.cb-validation-error,
.cb-validation-success {
  + .cb-input-helper {
    // 2.5em: width of icon
    width: calc(100% - 2.5em);
  }
}

// ------------------------------------ RADIO +  CHECKBOX

// ------------------------------------ radio
.cb-radio {
  display: flex;

  label {
    cursor: pointer;
    align-items: flex-start;
  }

  input[type="radio"] {
    position: relative;
    margin-left: 1px;
    margin-top: 1px;

    height: 22px;
    width: 22px;
    min-width: 22px;
    margin-right: 8px;

    &:before {
      content: "";
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      left: -1px;
      top: -1px;

      height: 24px;
      width: 24px;
      min-width: 24px;
      background-color: $white;
      transition: all $transition-time-s ease-out;
      border: 1px solid $black1;
      border-radius: 50%;
      outline: none;
    }
    &::after {
      position: absolute;
      display: block;
      box-sizing: border-box;
      content: "";
      left: 11px;
      top: 11px;
      height: 0px;
      width: 0px;
      border-radius: 50%;
      background-color: $blue5;
      border: 0 solid $blue5;
      opacity: 1;
      transition: all $transition-time ease-out;
    }

    &:checked {
      &::before {
        border-color: $blue5;
        border-width: 2px;
        background-color: $white;
      }
      &::after {
        width: 0;
        height: 0;
        border: 7px solid $blue5;
        top: 4px;
        left: 4px;
      }
    }

    &:hover:not(:checked),
    &:focus:not(:checked) {
      &::before {
        border: 1px solid $blue5;
        background: $gray5;
      }
    }
  }
  .cb-label {
    margin-left: 8px;
  }

  &.cb-condensed {
    input[type="radio"] {
      height: 14px;
      width: 14px;
      margin-right: 4px;
      min-width: 14px;
      margin-top: 5px;

      &:before {
        left: -1px;
        top: 0;

        height: 16px;
        width: 16px;
        min-width: 16px;
      }
      &::after {
        left: 8px;
        top: 8px;
      }

      &:checked {
        &::after {
          border: 4px solid $blue5;
          top: 4px;
          left: 3px;
        }
      }
    }
    .cb-label {
      margin-left: 4px;
    }
  }
}

// ------------------------------------ checkbox
.cb-checkbox {
  display: flex;

  label {
    cursor: pointer;
    align-items: flex-start;
  }

  input[type="checkbox"] {
    position: relative;
    margin-left: 1px;
    margin-top: 1px;

    height: 22px;
    width: 22px;
    min-width: 22px;
    margin-right: 8px;

    &::before {
      content: "";
      cursor: pointer;
      position: absolute;
      left: -1px;
      top: -1px;

      height: 24px;
      width: 24px;
      min-width: 24px;
      background-color: $white;
      border-radius: 4px;
      transition: all $transition-time-s ease-out;
      border: 1px solid $black1;
      outline: none;
    }
    &::after {
      position: absolute;
      content: "";
      left: 8px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 4px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all $transition-time ease-out;
    }

    &:checked {
      &::before {
        border-color: $blue5;
        background-color: $blue5;
      }
      &::after {
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }

    &:hover:not(:checked),
    &:focus:not(:checked) {
      outline: none;
      &::before {
        border: 1px solid $blue5;
        background: $gray5;
      }
    }
  }
  .cb-label {
    margin-left: 9px;
  }

  &.cb-condensed {
    input[type="checkbox"] {
      height: 14px;
      width: 14px;
      min-width: 14px;
      margin-right: 4px;
      margin-top: 5px;
      &::before {
        height: 16px;
        width: 16px;
        min-width: 16px;
        top: 0;
      }

      &:checked {
        &::after {
          left: 5px;
          top: 2px;
          width: 4px;
          height: 9px;
          border-width: 0 1px 1px 0;
        }
      }
    }
    .cb-label {
      margin-left: 5px;
    }
  }

  &.cb-checkbox-nested {
    &.cb-checkbox-mixed {
      input[type="checkbox"] {
        &::before {
          border-color: $blue5 !important;
          background-color: $blue5 !important;
        }
        &::after {
          transform: rotate(90deg) scale(1);
          top: 3px;
          width: 6px;
          height: 12px;
          border: solid $white;
          border-width: 0 2px 0 0;
          background-color: transparent;
          border-radius: 0;

          transition: all 0.1s ease-out;
        }
      }
    }
    input[type="checkbox"] {
      &:checked {
        &::after {
          transform: rotate(45deg) scale(1);
          opacity: 1;
          border-width: 0 2px 2px 0;
        }
      }
    }

    &.cb-condensed {
      &.cb-checkbox-mixed {
        input[type="checkbox"] {
          &::after {
            left: 5px;
            top: 2px;
            width: 4px;
            height: 9px;
            border-width: 0 1px 0 0;
          }
        }
      }
      input[type="checkbox"] {
        &:checked {
          &::after {
            border-width: 0 1px 1px 0;
            transform: rotate(45deg) scale(1);
          }
        }
      }
    }
  }
}

// ------------------------------------ disabled
.cb-radio,
.cb-checkbox {
  &.cb-disabled,
  &.disabled,
  fieldset[disabled] & {
    label {
      color: $cb-disabled-font-color;
    }

    input {
      &::before {
        cursor: not-allowed !important;

        border: 1px solid $gray4 !important;
        background: $gray5 !important;
      }
    }

    input[type="checkbox"] {
      &:checked {
        &::after {
          border-color: $gray4;
        }
      }
    }

    input[type="radio"] {
      &:checked {
        &::after {
          background: $gray4;
          border-color: $gray4;
          top: 4px;
          left: 4px;
        }
      }
    }
  }
}

// ------------------------------------ helper text
.cb-checkbox,
.cb-radio {
  + .cb-input-helper {
    margin-top: 8px;
  }
}

// ------------------------------------ TABLE
.cb-table:not(.cb-input-default) {
  td {
    .cb-input {
      input {
        background: $gray4;
        padding: 0 6px;
        font-size: $font-size-small;
      }
      .cb-btn {
        width: 1.1em;
        height: 1.1em;
        top: 3px !important;

        .cb-glyph,
        .cb-icon {
          font-size: $font-size-small !important;
          line-height: 1em !important;
        }
      }
      &.cb-floating-label {
        margin-top: 2px;
        label {
          font-size: $font-size-small;
          top: 2px;
          background: transparent;

          &.cb-value-fl,
          &.cb-focus-fl {
            font-size: $font-size-xsmall;
            left: 0;
            top: -16px;
          }
        }
      }
    }
  }
}

// ------------------------------------ LEGEND
legend {
  font-size: 1.625rem; //26
  line-height: 1.2307692307692308em; //32
  padding: 0;

  &.cb-label {
    font-size: $font-size-small;
    line-height: $line-height-small;
    // text-transform: uppercase;
    color: $gray1;
    font-weight: 500;
  }
}

// ------------------------------------ TOGGLE BUTTON
.cb-toggles-btn {
  display: inline-flex;
  font-size: 0;

  label {
    position: relative;
    padding: 0;
    margin: 0;
    width: 48px;
    height: 48px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include border-box();

    &:hover {
      cursor: pointer;
    }

    input[type="radio"] {
      position: absolute;
      top: 10px;
      left: 10px;
      margin: 0;
      padding: 0;

      + span:not(.cb-glyph):not(.cb-icon) {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 48px;
        height: 48px;
        color: $black1;
        @include roboto-bold();
        font-size: 0.75rem;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;

        border-top: 1px solid $gray4;
        border-bottom: 1px solid $gray4;

        & + .cb-glyph,
        & + .cb-icon {
          font-size: 1rem;
          width: 1em;
          height: 1em;

          z-index: 3;
        }
      }

      &:hover,
      &:focus,
      &:checked,
      &.cb-checked {
        outline: 0;
        box-shadow: none;
      }

      &:hover {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray5;
        }
      }

      &:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $white;
          border: 1px solid $black1;
        }
      }

      &:active:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray3;
          border: 1px solid $gray4;
        }
      }

      &:checked {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray4;
          text-decoration: underline;

          & + .cb-glyph,
          & + .cb-icon {
            text-decoration: underline;
          }
        }
      }

      // matching secondary disabled button style
      &[disabled],
      &.disabled,
      fieldset[disabled] & {
        & + span:not(.cb-glyph):not(.cb-icon) {
          background-color: $gray5;
          color: $gray4;
          cursor: not-allowed;

          + .cb-glyph,
          + .cb-icon {
            color: $gray4;
            cursor: not-allowed;
          }
        }
      }
    }

    &:first-of-type {
      input[type="radio"] {
        & + span:not(.cb-glyph):not(.cb-icon) {
          border-left: 1px solid $gray4;
          border-top-left-radius: $btn-border-radius-input;
          border-bottom-left-radius: $btn-border-radius-input;
        }

        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            border-left: 1px solid $black1;
          }
        }
      }
    }

    &:last-of-type {
      input[type="radio"] {
        & + span:not(.cb-glyph):not(.cb-icon) {
          border-right: 1px solid $gray4;
          border-top-right-radius: $btn-border-radius-input;
          border-bottom-right-radius: $btn-border-radius-input;
        }

        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            border-right: 1px solid $black1;
          }
        }
      }
    }
  }
}

// ------------------------------------ TOGGLE SWITCH

.cb-toggle-switch {
  display: inline-flex;
  justify-content: center;
  width: fit-content;
  align-items: center;

  .cb-switch {
    display: inline-block;
    position: relative;
    outline: none;

    .cb-switch-track {
      box-sizing: border-box;
      width: 48px;
      height: 24px;
      border: 1px solid;
      border-radius: 999px;
      background: $gray2;
      border-color: $gray2;
      transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .cb-switch-wrap {
      left: -12px;
      right: auto;
      display: flex;
      position: absolute;
      top: -12px;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      transform: translateX(0);
      transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 90ms cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);

      .cb-switch-thumb {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        z-index: 1;
        // background: $white;
        border: 11px solid $white;
      }
    }

    &.cb-checked {
      .cb-switch-track {
        background: $blue5;
        border-color: $blue5;
      }

      .cb-switch-wrap {
        transform: translateX(24px);
      }
    }

    &.cb-disabled {
      .cb-switch-track {
        background: $gray4;
        border-color: $gray4;
      }

      .cb-switch-wrap {
        .cb-switch-thumb {
          background: $gray5;
        }
      }
      + label {
        color: $cb-disabled-font-color;
      }
      input {
        cursor: not-allowed;
      }
    }

    &:hover:not(.cb-disabled) {
      .cb-switch-track {
        background: $gray1;
        border-color: $gray1;
      }

      .cb-switch-wrap {
        .cb-switch-thumb {
          background: $gray5;
        }
      }
    }

    &.cb-checked {
      &:hover:not(.cb-disabled) {
        .cb-switch-track {
          background: $blue5-shade1;
          border-color: $blue5-shade1;
        }

        .cb-switch-wrap {
          .cb-switch-thumb {
            background: $gray5;
          }
        }
      }
    }

    input {
      left: 0;
      right: auto;
      position: absolute;
      top: 0;
      width: 68px;
      height: 48px;
      margin: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  label {
    cursor: pointer;
    & + .cb-switch {
      margin-left: 8px;
    }
  }

  .cb-switch {
    & + label {
      margin-left: 8px;
    }
  }
  &.cb-condensed {
    .cb-switch {
      .cb-switch-track {
        width: 32px;
        height: 16px;
      }

      .cb-switch-wrap {
        left: -8px;
        top: -8px;
        width: 32px;
        height: 32px;

        .cb-switch-thumb {
          width: 14px;
          height: 14px;
          border: 7px solid $white;
        }
      }

      &.cb-checked {
        .cb-switch-wrap {
          transform: translateX(16px);
        }
      }

      input {
        left: 0;
        top: 4px;
        width: 32px;
        height: 24px;
      }
    }

    label {
      & + .cb-switch {
        margin-left: 4px;
      }
    }

    .cb-switch {
      & + label {
        margin-left: 4px;
      }
    }
  }
}

// ------------------------------------ REQUIRED
.cb-required {
  &::after {
    content: "*";
    font-size: 1rem;
    margin-left: 3px;
  }
}

// ------------------------------------ DROPDOWN INPUT
.cb-input-dropdown {
  .cb-dropdown-input {
    position: relative;

    .cb-dropdown-menu {
      a {
        // black
        @include black-link();
      }

      top: 0;
      left: 0;
      z-index: 899;
      display: none;
      min-width: 100%;
      max-width: 100%;

      background-color: $white;

      @include roboto();
      font-size: $font-size-small;
      line-height: $line-height-small;

      // UL
      ul {
        list-style: none;
        padding: 0 0 12px 0px;

        > li {
          > a {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            padding: 12px 24px;
            font-size: 0.875rem;
            line-height: 1.1428571428571428em;
            // white-space: nowrap;
            @include media-breakpoint-down("sm", $grid-breakpoints) {
              padding: 16px 24px;
              white-space: normal;
            }

            text-decoration: none !important;
            transition: all $transition-time-s ease-in-out;

            &:hover,
            &:active {
              background-color: $gray5;
              box-shadow: inset 2px 0px 0px 0px $black1;
            }

            .cb-glyph,
            .cb-icon {
              color: $gray3;
              margin-left: 8px;
            }
          }
        }
        &.cb-dropdown-left-icon {
          > li {
            a {
              justify-content: flex-start;
              .cb-glyph,
              .cb-icon {
                margin-left: 0;
                margin-right: 8px;
              }
            }
          }
        }
      }

      li {
        &.cb-dropdown-header {
          padding: 4px 24px;

          h3,
          h4,
          h5 {
            font-size: $font-size-xsmall;
            line-height: 1.3333333333333333em;
            color: $gray2;
            font-weight: 700;
          }
        }

        &.cb-disabled {
          a {
            color: $gray3;

            &:hover,
            &:focus,
            &:active {
              color: $gray3;
              cursor: not-allowed;
            }
          }
        }

        &.cb-divider {
          height: 1px;
          overflow: hidden;
          margin: 12px 0;
          background-color: $gray4;
          padding: 0;
        }

        input {
          &:focus {
            outline: 0;
          }
        }
      }
    }

    .cb-dropdown-container {
      position: relative;
      &.cb-has-dropdown-overlay {
        position: absolute;
        top: -9px;
        z-index: 899;
        min-width: 100%;
        max-width: 100%;
      }
      .cb-dropdown-helper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: $font-size-small;
        line-height: $line-height-small;
        border-bottom-left-radius: $border-radius-input;
        border-bottom-right-radius: $border-radius-input;
        padding: 8px 16px;
        text-align: center;
        z-index: 900;
        // display: none;
        transition-duration: 0s;
        transition-timing-function: ease-in-out;
        transform: scaleY(0);
        transform-origin: bottom;
      }
    }
  }

  border-radius: $btn-border-radius-input;

  &.cb-input {
    &.cb-open {
      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: transparent;
        border-bottom: 0;
        box-shadow: none;
      }

      .cb-dropdown-menu {
        position: relative;
        display: block;

        border: 1px solid transparent;

        border-top: 0;
        border-bottom-left-radius: $border-radius-input;
        border-bottom-right-radius: $border-radius-input;
      }
      .cb-dropdown-container {
        .cb-dropdown-helper {
          &.cb-show {
            // display: block;
            border-top: 1px solid $gray4;
            transition-duration: $transition-time-s;
            transform: scaleY(1);
            background-color: $white;
          }
        }
      }

      .cb-input-icon-right {
        .cb-glyph,
        .cb-icon {
          &:not(.cb-x-mark) {
            display: none;
          }
        }
      }
    }
    &.cb-floating-label {
      label {
        &:not(.cb-value-fl),
        &:not(.cb-focus-fl) {
          width: calc(100% - 32px);
          justify-content: start;
        }
        &.cb-value-fl,
        &.cb-focus-fl {
          width: auto;
          justify-content: center;
        }
      }
      .cb-input-icon-left,
      .cb-input-icon-right {
        label {
          &:not(.cb-value-fl),
          &:not(.cb-focus-fl) {
            width: calc(100% - 48px);
            justify-content: start;
          }
          &.cb-value-fl,
          &.cb-focus-fl {
            width: auto;
            justify-content: center;
          }
        }
      }
    }
  }

  &.cb-open {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }

  .cb-btn {
    top: 8px !important;
  }
  &.cb-input-dropdown-overlay {
    &.cb-input {
      &.cb-open {
        .cb-dropdown-menu {
          top: -9px;
          position: absolute;
          box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.2);
        }
        .cb-has-dropdown-overlay {
          background-color: $white;
          box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.2);
          border-bottom-left-radius: $border-radius-input;
          border-bottom-right-radius: $border-radius-input;
          .cb-dropdown-menu {
            top: 0;
            box-shadow: none;
          }
        }
      }
    }
  }

  &.cb-input-dropdown-scroll {
    .cb-dropdown-input {
      .cb-dropdown-menu {
        overflow-y: auto;
      }
    }
  }
}

.cb-input-dropdown-overflow {
  position: relative;
  width: 100%;
  min-height: 48px;
  .cb-input-dropdown {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

// ------------------------------------ FOCUS

[data-cb-input-m="keyboard"] {
  // ------------------------------------ toggle switch
  .cb-toggle-switch {
    .cb-switch {
      &:focus,
      &.cb-focus {
        .cb-switch-track {
          background: $gray2;
          border-color: $white;
          box-shadow: 0 0 0 2px $blue5;
        }

        .cb-switch-wrap {
          .cb-switch-thumb {
            background: $white;
            border-color: $white;
          }
        }
      }

      &.cb-checked {
        &:focus,
        &.cb-focus {
          .cb-switch-track {
            background: $blue5;
            border-color: $white;
            box-shadow: 0 0 0 2px $blue5;
          }

          .cb-switch-wrap {
            .cb-switch-thumb {
              background: $gray5;
              border-color: $white;
            }
          }
        }
      }
    }
  }

  // ------------------------------------ radio
  .cb-radio {
    input[type="radio"] {
      &:focus {
        outline: none;
        &::before {
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }

  // ------------------------------------ checkbox
  .cb-checkbox {
    input[type="checkbox"] {
      &:focus {
        outline: none;
        &::before {
          border: 1px solid $white;
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }

  // ------------------------------------ toggle button

  .cb-toggles-btn {
    input[type="radio"] {
      &:focus {
        & + span:not(.cb-glyph):not(.cb-icon) {
          box-shadow: inset 0 0 1px 2px $gray1;
        }
      }
    }
  }

  // ------------------------------------ input button
  .cb-btn-input:not(.cb-clear-input):not(.cb-password-input) {
    .cb-btn {
      &:focus {
        box-shadow: none;

        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }

  // ------------------------------------ password input

  .cb-btn-input {
    &.cb-password-input {
      .cb-btn {
        &:focus {
          box-shadow: none;

          .cb-glyph,
          .cb-icon {
            color: $black1;
            font-weight: 900;
          }
        }
      }
    }
  }

  // ------------------------------------ input dropdown
  .cb-input-dropdown {
    .cb-dropdown-input {
      .cb-dropdown-menu {
        // UL
        ul {
          > li {
            > a {
              &:focus {
                outline: 0;
                box-shadow: inset 0 0 0 2px $blue5;
              }

              &:focus[aria-disabled="true"] {
                outline: 0;
                box-shadow: inset 0 0 0 2px $gray4;
              }
            }
          }
        }
      }
    }
  }
}

// ------------------------------------ label left
.cb-label-left {
  padding: 8px;
  background: transparent;
  margin-left: -8px;
  &.cb-toggle-switch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    label {
      flex-grow: 1;
      justify-content: start;
    }
  }

  &.cb-radio,
  &.cb-checkbox {
    label {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      &.cb-required {
        input {
          margin-left: auto;
        }
      }
    }
    .cb-label {
      margin-left: 0;
    }
    // input[type="radio"],
    // input[type="checkbox"] {
    //   margin-right: 8px;
    // }
  }

  &:hover {
    cursor: pointer;
    border-radius: 4px;
    background: $gray5;
    transition: all $transition-time-s ease-in-out;
  }
}

// ------------------------------------ IE 10, 11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cb-btn-input {
    .cb-btn {
      display: none !important;
    }
  }
}

@import "forms-select.scss";

@import "forms-floating-label.scss";
@import "forms-validation.scss";

@import "forms-clear-input.scss";
@import "forms-stepper-input.scss";

@import "forms-file.scss";
@import "forms-multi-select.scss";
