// -----------------------------
// CB Modals
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/font.scss";

.cb-modal {
  display: none;

  &.cb-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .cb-modal-overlay {
      animation: cbFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .cb-modal-container {
      animation: cbSlideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    // A11Y
    @media (prefers-reduced-motion: reduce) {
      .cb-modal-overlay {
        animation: cbFadeIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: cbSlideIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &.cb-no-animation-open {
      .cb-modal-overlay {
        animation: cbFadeIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
      .cb-modal-container {
        animation: cbSlideIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  &[aria-hidden="true"] {
    .cb-modal-overlay {
      animation: cbFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .cb-modal-container {
      animation: cbSlideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    // A11Y
    @media (prefers-reduced-motion: reduce) {
      .cb-modal-overlay {
        animation: cbFadeOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: cbSlideOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }

    &.cb-no-animation-close {
      .cb-modal-overlay {
        animation: cbFadeOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
      .cb-modal-container {
        animation: cbSlideOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  .cb-modal-container {
    background-color: $modal-bg;
    padding: $modal-spacing;

    width: 900px;

    // Desktop
    @include media-breakpoint-down("md", $grid-breakpoints) {
      width: 600px;
    }

    // Mobile
    @include media-breakpoint-down("xs", $grid-breakpoints) {
      width: auto;
    }

    max-height: calc(100vh - 48px);
    max-width: calc(100vw - 48px);

    box-sizing: border-box;
    &:not(.cb-modal-overflow) {
      overflow: hidden;
    }

    border-radius: $modal-border-radius;

    // Mobile
    @include media-breakpoint-down("xs", $grid-breakpoints) {
      margin: 12px;
    }

    .cb-modal-header {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 30px;
      margin-bottom: 24px;

      .cb-modal-title {
        font-size: 1.3125rem;
        line-height: 1.1428571428571428em;
        font-weight: 500;
        @include media-breakpoint-up("sm", $grid-breakpoints) {
          font-size: 1.75rem; //28
          line-height: 1.1428571428571428em; //32
          font-weight: 400;
        }
        //lg, xl, 2xl, 3xl
        @include media-breakpoint-up("lg", $grid-breakpoints) {
          font-size: 2rem; //32
          line-height: 1.25em; //40
          font-weight: 400;
        }
      }

      &.cb-modal-title-center {
        .cb-modal-title {
          text-align: center;
          width: 100%;
        }
      }
      &.cb-modal-has-close {
        .cb-modal-title {
          max-width: calc(100% - 40px);
        }
        &.cb-modal-title-center {
          .cb-modal-title {
            width: calc(100% - 40px);
            padding-left: 40px;
          }
        }
      }

      .cb-btn-close {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .cb-modal-content {
      margin-bottom: 24px;
    }
    &.cb-modal-overflow {
      .cb-modal-content {
        overflow-y: unset !important;
      }
    }

    .cb-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  // backward compatibility
  &.cb-dialog-modal {
    .cb-modal-container {
      padding: 0;

      .cb-modal-content {
        margin-bottom: 0;
        overflow: hidden !important;
        height: auto !important;
      }
      .cb-notification {
        padding: 0;
        margin-bottom: 0;
        border: 0;

        .cb-notification-container {
          .cb-btn.cb-btn-close {
            margin-right: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  &.cb-video-modal {
    .cb-modal-container {
      background-color: grey;
      padding: 0;
      .cb-modal-header {
        margin-bottom: 0;
        position: fixed;
        top: 24px;
        right: 24px;
      }
      .cb-modal-content {
        position: relative;
        margin: 0;
        width: 100%;
        height: 100%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }

  &.cb-spinner-modal {
    .cb-modal-container {
      padding: 0 !important;
      background-color: transparent !important;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      max-width: 100px;
      min-height: 100px;
      outline: 0 !important;
      .cb-modal-content {
        margin: 0 !important;
        height: 100% !important;
        overflow: unset !important;
      }
    }
  }

  &.cb-photo-gallery-modal {
    .cb-modal-container {
      padding: 0;
    }
  }

  &.cb-promo-modal {
    .cb-modal-container {
      padding: 0 !important;
      background-color: transparent!important;
      display: flex;
      .cb-promo-modal-container {
        padding: 24px;
        height: 100%;
        background-color: $white;
      }
      .cb-promo-modal-img {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        &.cb-img-contain {
          background-size:contain;
        }

        // Per breakpoint
        @each $breakpoint, $value in $grid-breakpoints {
          @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-only {
              background-size:contain!important;
            }
          }
          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-up {
              background-size:contain!important;
            }
          }
          @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-down {
              background-size:contain!important;
            }
          }
        }



      }

      &.cb-promo-modal-top {
        flex-direction: column;
        .cb-promo-modal-img {
          height: 384px;
          min-height: 384px;
          position: relative;
          .cb-btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }
          @include media-breakpoint-only("md", $grid-breakpoints) {
            height: 362px;
            min-height: 362px;
          }
          @include media-breakpoint-only("sm", $grid-breakpoints) {
            height: 267px;
            min-height: 267px;
          }
          @include media-breakpoint-only("xs", $grid-breakpoints) {
            height: 153px;
            min-height: 153px;
          }
        }
      }
      &.cb-promo-modal-left {
        .cb-promo-modal-container {
          margin-left: 50%;
        }
      }
      &.cb-promo-modal-right {
        .cb-promo-modal-img {
          right: 0;
          .cb-btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }

      &.cb-promo-modal-left,
      &.cb-promo-modal-right {
        position: relative;
        .cb-promo-modal-img {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50%;
          min-width: 50%;
        }
        .cb-promo-modal-container {
          width: 50%;
          min-width: 50%;
        }

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          flex-direction: column;
          .cb-promo-modal-img {
            margin-right: 0;
            position: relative;
            height: 153px;
            min-height: 153px;
            width: 100%;
            min-width: 100%;
            border-top-right-radius: $modal-border-radius;
            .cb-btn-close {
              position: absolute;
              top: 16px;
              right: 16px;
            }
          }
          .cb-promo-modal-container {
            margin-left: 0;
            border-bottom-right-radius: $modal-border-radius;
            width: 100%;
            min-width: 100%;
            .cb-modal-title {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

.cb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $modal-backdrop-bg;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: $zindex-modal-background;
  &.cb-modal-over-tooltip {
    z-index: $zindex-modal-background-high;
  }
}

.cb-modal-open {
  overflow: hidden;
}
.cb-photo-gallery-modal {
  .cb-modal-overlay {
    background-color: $black1;
    .cb-modal-container {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      border-radius: 0;
      margin: 0;

      .cb-modal-content {
        margin-bottom: 0;
      }
    }
  }
}

// A11Y
[data-cb-input-m="keyboard"] {
  .cb-modal {
    &.cb-video-modal {
      .cb-modal-container {
        .cb-modal-footer {
          .cb-btn-row {
            position: relative;
            .cb-btn[data-cb-modal-close] {
              position: absolute;
              right: -200px;
              &:focus {
                right: 4px;
                &::after {
                  box-shadow: inset 0 0 0 2px $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Animation Style
@keyframes cbFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes cbFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes cbSlideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}
@keyframes cbSlideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

// For no motion
@keyframes cbFadeIn-motion {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}
@keyframes cbFadeOut-motion {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}
@keyframes cbSlideIn-motion {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(0);
  }
}
@keyframes cbSlideOut-motion {
  from {
    transform: translateY(-10%);
  }

  to {
    transform: translateY(-10%);
  }
}
