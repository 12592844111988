// -----------------------------
// CB Dropdown
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/link.scss";

.cb-dropdown {
  position: relative;

  a {
    // black
    @include black-link();
  }

  > a {
    display: inline-flex;
    align-items: flex-end;
    padding: 0 24px 12px;
    height: 48px;

    &:hover,
    &:active,
    &:focus {
      box-shadow: inset 0px -3px 0px -1px $black1;
    }

    &:focus {
      outline: 0;
    }
  }

  &.cb-open {
    > a {
      box-shadow: inset 0px -3px 0px -1px $black1;
      &:focus {
        outline: 0;
      }
    }
  }

  &.cb-disabled {
    > a {
      color: $gray3;

      &:hover,
      &:focus,
      &:active {
        color: $gray3;
        cursor: not-allowed;
        box-shadow: none;
      }
    }
  }

  &.cb-dropdown-condensed {
    height: 40px;
  }

  .cb-dropdown-toggle {
    font-size: 0.875rem !important;
    line-height: 16px;
    text-decoration: none !important;
    transition: background-color $transition-time-s ease-in-out;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    .cb-glyph,
    .cb-icon {
      margin-right: 8px;
      &.cb-down,
      &.cb-up {
        font-size: 0.75rem;
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  .cb-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;

    z-index: 899;
    display: none;
    min-width: 100%;

    background-color: $white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include roboto();
    font-size: $font-size-small;
    line-height: $line-height-small;

    &.cb-menu-right,
    &.cb-menu-right-always {
      right: 0;
      left: auto;
    }

    .cb-input-container {
      padding: 24px 24px 16px 24px;

      margin-bottom: 12px;
      border-bottom: 1px solid $gray4;

      .cb-input-helper {
        margin-top: 8px;
      }
    }

    // UL
    ul {
      list-style: none;
      padding: 12px 0px;
      ul {
        padding: 0px;
      }

      > li {
        > a {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          padding: 12px 24px;
          font-size: 0.875rem;
          line-height: 1.1428571428571428em;

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            padding: 16px 24px;
          }

          text-decoration: none;
          transition: background-color $transition-time-s ease-in-out;
          transition: box-shadow $transition-time-s ease-in-out;
          white-space: nowrap;
          &:focus {
            text-decoration: underline;
          }
          &:hover,
          &:active {
            background-color: $gray5;
            box-shadow: inset 2px 0px 0px 0px $black1;
            text-decoration: none;
          }

          &:focus[aria-disabled="true"] {
            box-shadow: inset 0 0 0 2px $gray4;
          }

          .cb-glyph,
          .cb-icon {
            color: $gray3;
            margin-left: 8px;
          }

          &.cb-selected {
            box-shadow: inset 2px 0px 0px 0px $black1;
          }
        }

        &.cb-disabled {
          > a {
            &:hover,
            &:active {
              box-shadow: none;
            }
          }
        }
      }
      &.cb-dropdown-left-icon {
        > li {
          > a {
            justify-content: flex-start;
            .cb-glyph,
            .cb-icon {
              margin-left: 0;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .cb-input-container {
      + ul {
        padding-top: 0;
      }
    }

    li {
      &.cb-dropdown-header {
        padding: 4px 24px;

        p,
        h3,
        h4,
        h5 {
          font-size: $font-size-xsmall;
          line-height: 1.3333333333333333em;
          color: $gray2;
          font-weight: 700;
        }
      }

      &.cb-disabled {
        a {
          color: $gray3;

          &:hover,
          &:focus,
          &:active {
            color: $gray3;
            cursor: not-allowed;
          }
        }
      }

      &.cb-divider {
        height: 1px;
        overflow: hidden;
        margin: 12px 0;
        border-top: 1px solid $gray4;
      }

      input {
        &:focus {
          outline: 0;
        }
      }
    }
  }

  &.cb-open {
    .cb-dropdown-menu {
      display: block;

      &.cb-dropdown-menu-scroll {
        overflow-y: auto;
        min-height: 64px;

        @include media-breakpoint-down("sm", $grid-breakpoints) {
          min-height: 72px;
        }
      }
    }
  }
}

// ------------------------------------ FOCUS

[data-cb-input-m="keyboard"] {
  .cb-dropdown {
    .cb-dropdown-menu {
      // UL
      ul {
        > li {
          > a {
            &:focus {
              outline: 0;
              box-shadow: inset 0 0 0 2px $outlineColor !important;
              text-decoration: none !important;
            }

            &:focus[aria-disabled="true"] {
              outline: 0;
              box-shadow: inset 0 0 0 2px $outlineColor;
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }
}
