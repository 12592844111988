// -----------------------------
// CB Button Mixins
// -----------------------------

// Button sizes
@mixin button-size($padding-y, $padding-x) {
  padding: $padding-y - 1px $padding-x - 1px;
}

@mixin button-active(
  $background,
  $border: $btn-border-color,
  $color: $btn-font-color,
  $decoration: none
) {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).cb-active {
    background-color: $background;
    border-color: $border;
    color: $color;
    text-decoration: $decoration;
  }
}

@mixin button-disabled(
  $background,
  $border,
  $color: $btn-font-color,
  $opacity: $btn-disabled-opacity
) {
  &:not(.cb-btn-pulse) {
    &.cb-disabled,
    &:disabled {
      opacity: $opacity;
      text-decoration: none !important;
      background-color: $background;
      border-color: $border;
      color: $color !important;
      cursor: not-allowed;
      box-shadow: none;
      span {
        text-decoration: none !important;
      }
    }
  }
}

// Loading Dots
@mixin dot(
  $width: $dotWidth,
  $height: $dotHeight,
  $radius: $dotRadius,
  $bgColor: $dotBgColor,
  $color: $dotColor
) {
  background-color: $bgColor;
  border-radius: $radius;
  color: $color;
  height: $height;
  width: $width;
}

@mixin light() {
  color: $white;
  border-color: $white;

  &:hover,
  &.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px $white;
  }

  &:focus,
  &.cb-focus {
    border-color: $white;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px $white;
    text-decoration: underline;
  }

  &.cb-disabled,
  &:disabled {
    text-decoration: none !important;
    &:not(.cb-btn-pulse) {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.4);
    }
    cursor: not-allowed;
    box-shadow: none;
  }
  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
  }
}

@mixin primary-light() {
  background-color: $white;
  color: $blue5;
  border-color: $white;

  &:hover,
  &.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px $white;
    color: $white;
  }

  &:focus,
  &.cb-focus {
    color: $blue5;
    border-color: $blue5;
    box-shadow: 0 0 0 3px $white;
  }

  &.cb-disabled,
  &:disabled {
    &:not(.cb-btn-pulse) {
      text-decoration: none !important;
      background-color: rgba(255, 255, 255, 0.1);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.4);
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &:not(.cb-btn-pulse) {
    &:hover:not(:focus),
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
    &:focus:not(:hover),
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $blue5;
        }
      }
    }
  }
  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      color: $value;
    }
  }
}

@mixin yellow-light() {
  background-color: $white;
  color: $black1;
  border-color: $white;

  &:hover,
  &.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px $white;
    color: $white !important;
  }

  &:focus,
  &.cb-focus {
    color: $black1;
    border-color: $black1;
    box-shadow: 0 0 0 3px $white;
  }

  &.cb-disabled,
  &:disabled {
    &:not(.cb-btn-pulse) {
      text-decoration: none !important;
      background-color: rgba(255, 255, 255, 0.1);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.4) !important;
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &:not(.cb-btn-pulse) {
    &:hover:not(:focus),
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
    &:focus:not(:hover),
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $black1;
        }
      }
    }
  }
}

@mixin close {
  border: transparent;
  width: 2em;
  height: 2rem;
  font-size: 1rem;
  line-height: 1em;
  padding: 0;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0.875em;
    font-size: $font-size-small;
    padding: 0;
  }

  @include button-disabled(transparent, transparent, $gray2);

  &:focus,
  &.cb-focus {
    background-color: transparent !important;
  }

  &.cb-white-color {
    background: transparent;

    &:hover {
      background-color: $black1 !important;
    }
  }
}
