// -----------------------------
// CB Tables
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/table.scss";
@import "../mixins/breakpoint.scss";

// ----------------- BASE
.cb-table {
  @include table();

  // Table Utility classes
  &.cb-no-border {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;

          &:first-child {
            border-left: 0;
          }
        }

        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
    &.cb-table-light {
      > thead {
        > tr {
          &:last-child {
            > th,
            > td {
              border-bottom: 2px solid $table-border-color;
            }
          }
        }
      }
    }
  }

  &.cb-no-border-left-right {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;
          border-left: 0;
        }

        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
    &.cb-table-light {
      > thead {
        > tr {
          &:last-child {
            > th,
            > td {
              border-bottom: 2px solid $table-border-color;
            }
          }
        }
      }
    }
  }

  &.cb-table-condensed {
    th,
    td {
      padding: $table-condensed-cell-padding;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &.cb-table-striped {
    > tbody > tr {
      &:nth-of-type(even) {
        background-color: $white;
      }

      &:nth-of-type(odd) {
        background-color: $gray5;
      }
    }
  }

  &.cb-table-striped-reverse {
    > tbody > tr {
      &:nth-of-type(odd) {
        background-color: $white;
      }

      &:nth-of-type(even) {
        background-color: $gray5;
      }
    }
  }

  // ------------------------------------ CELL
  .cb-table-sort {
    padding: 0 !important;
    vertical-align: middle;
    border-left-width: 0;
    border-right-width: 0;
    background: $white;
    a {
      padding: 5px 10px;
      text-decoration: none !important;
      display: block;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }

      .cb-sort-desc,
      .cb-sort-asc {
        color: $blue5;
      }

      .cb-sort {
        color: $black1;
      }

      &:hover {
        background-color: $blue5Tint1;
        cursor: pointer;
      }
    }

    &:first-of-type {
      border-left-width: 1px;
    }

    &:last-of-type {
      border-right-width: 1px;
    }
  }
  &.cb-table-condensed {
    .cb-table-sort {
      a {
        padding: $table-condensed-cell-padding;
      }
    }
  }

  .cb-table-sort-joined {
    a {
      color: $white;
      text-decoration: none !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: $white;
        span {
          &:nth-of-type(1) {
            text-decoration: underline;
          }
          &.cb-glyph,
          &.cb-icon {
            text-decoration: none !important;
          }
        }
      }

      .cb-sort-desc,
      .cb-sort-asc,
      .cb-sort {
        color: $white;
      }
    }

    &:hover,
    &:focus {
      background-color: $black1;
      a {
        color: $white !important;
        .cb-glyph,
        .cb-icon {
          color: $white !important;
        }
      }
      cursor: pointer;
    }
  }

  &.cb-table-light {
    .cb-table-sort-joined {
      a {
        color: $black1 !important;
        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: $black1;
          span {
            &:nth-of-type(1) {
              text-decoration: underline;
            }
            &.cb-glyph,
            &.cb-icon {
              text-decoration: none !important;
            }
          }
        }

        .cb-sort-desc,
        .cb-sort-asc {
          color: $blue5;
        }

        .cb-sort {
          color: $black1;
        }
      }

      &:hover {
        background-color: $blue5Tint1;
        cursor: pointer;
        a,
        .cb-sort,
        .cb-glyph,
        .cb-icon {
          color: $black1 !important;
        }
        .cb-sort-desc,
        .cb-sort-asc {
          color: $blue5 !important;
        }
      }
    }
  }

  .cb-table-thumbnail {
    width: 72px;
    height: 72px;
    // .border-box();
    box-sizing: border-box;
    border: 1px solid $table-border-color;
    background-color: $gray3;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      z-index: 2;
      position: absolute;
    }

    &::before {
      content: "?";
      color: $table-border-color;
      font-size: 1.5rem;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & + p {
      display: inline-block;
      float: left;
    }
  }

  .cb-table-tip {
    &.cb-glyph,
    &.cb-icon {
      font-size: 0.75rem;
      line-height: 1em;
      display: inline-block;
      float: right;
    }
  }

  .cb-table-callout {
    background-color: $gray5;
  }

  > thead {
    .cb-table-callout,
    &.cb-table-callout {
      color: $black1 !important;
    }
  }

  .cb-table-highlight {
    background-color: $yellow2 !important;
  }
  .cb-table-selected {
    background-color: $blue5Tint1 !important;
  }

  &.cb-table-expandable {
    .cb-table-expandable-trigger {
      padding: 0 !important;
      min-width: 48px;
      max-width: 48px;
      width: 48px;
      height: 1px;
      .cb-btn {
        border: 0;
        border-radius: 0;
        height: 100% !important;
        &:focus {
          box-shadow: inset 0 0 0 2px $black1;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.16);
          box-shadow: none;
        }
      }
    }
    .cb-table-expandable-row {
      td {
        &.cb-table-expandable-trigger-right {
          padding-right: 56px;
        }
        &.cb-table-expandable-trigger-left {
          padding-left: 56px;
        }
      }
    }
    &.cb-table-condensed {
      .cb-table-expandable-trigger {
        min-width: 32px;
        max-width: 32px;
        width: 32px;
        .cb-btn {
          width: 32px;
          height: 32px;
          line-height: 0.875em;
          font-size: $font-size-small;
          padding: 0;
        }
      }
      .cb-table-expandable-row {
        td {
          &.cb-table-expandable-trigger-right {
            padding-right: 36px;
          }
          &.cb-table-expandable-trigger-left {
            padding-left: 36px;
          }
        }
      }
    }
    &.cb-table-striped,
    &.cb-table-striped-reverse {
      tbody {
        tr {
          background-color: $white;
        }
        .cb-stripe {
          background-color: $gray5;
        }
        .cb-no-stripe {
          background-color: $white;
        }
      }
    }
  }
  th {
    &.cb-no-padding-th {
      padding: 0 !important;
    }
  }
  td {
    &.cb-no-padding-td {
      padding: 0 !important;
    }
  }
  .cb-table-cell-padding {
    padding: $table-cell-padding;
  }
  .cb-table-condensed-cell-padding {
    padding: $table-condensed-cell-padding;
  }
}

// ----------------- RESPONSIVE
.cb-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  // Prevent double border on horizontal scroll due to use of `display: block;`
  > .cb-table {
    border: 0;
  }
}

.cb-table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .cb-table {
          border: 0;
        }
      }
    }
  }
}

// ----------------- SCROOLBAR
.cb-table-scroll {
  overflow: auto;
}

// ----------------- MOBILE
.cb-table-mobile {
  td {
    width: 50%;
  }
  .cb-last-row {
    td,
    th {
      border-bottom-color: $gray2;
      padding-bottom: 24px;
    }
    &:last-of-type {
      td,
      th {
        border-bottom-color: $gray2;
      }
    }
    & + tr {
      td,
      th {
        padding-top: 24px;
      }
    }
  }
}

// ----------------- STICKY PLUGIN
.cb-table-sticky {
  &.cb-active-sticky {
    position: relative;
    overflow: auto;
    border: 1px solid $table-border-color;
    border-top: 0;
    .cb-table {
      border-bottom: 0px !important;
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }

      > caption {
        border-left: 0;
        border-right: 0;
      }
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

      thead {
        .cb-sticky-cell {
          background: $gray1;
          color: $white;
          border-right: 0;
        }
        &.cb-active-header {
          box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
        }

        &.cb-active-column {
          tr {
            &.cb-sticky-cell {
              border-right: 0;
            }

            .cb-sticky-cell-last {
              border-right: 1px solid $table-border-color;
              box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      tbody {
        .cb-sticky-cell {
          background: $white;
          border-right: 0;
        }
        &.cb-active-column {
          .cb-sticky-cell-last {
            border-right: 1px solid $table-border-color;
            box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

// ----------------- STICKY CSS
.cb-table-sticky-css {
  display: block;
  width: 100%;
  overflow: auto;
  border: 1px solid $table-border-color;
  .cb-table {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;
          border-top: 0;
          &:first-child {
            border-left: 0;
          }
        }
      }
    }
    thead {
      &.cb-active-header {
        tr {
          th {
            position: sticky;
            z-index: 3;
            left: 0;
            top: -1px;
            background: $gray1;
            box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
            &.cb-active-column {
              z-index: 4;
              box-shadow: none;
            }
          }
        }
      }
    }
    tbody {
      &.cb-active-column {
        tr {
          th,
          td {
            &:nth-child(1) {
              position: sticky;
              left: 0px;
              top: -1px;
              z-index: 3;
              background: $white;
              box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.1);
              border-right: 1px solid $table-border-color;
            }
          }
        }
      }
    }
  }
}

// ------- Related to React Components

.cb-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 72px;
  z-index: 50000;
}

.cb-table-wrapper {
  &.with-top-scroll {
    overflow-x: auto;
  }
}

.cb-table-top-scroll-holder {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  .cb-table-top-scroll {
    height: 1px;
  }
}

.cb-table-react {
  &.cb-table {
    tbody {
      th {
        font-weight: initial;
      }
    }
  }
  // sticky header relative to page
  &.cb-table-header {
    position: absolute;
    .cb-table-wrapper {
      overflow-x: hidden;
    }
    &.cb-sticky-table {
      position: fixed;
      top: 0;
      z-index: 900;
    }
    table {
      width: unset;
      td.cb-table-sort a.focused {
        outline-offset: 1px;
        outline: $outlineColor auto 1px;
      }
    }
  }

  // sticky header relative to parent
  // thead tag
  .cb-sticky-header-parent {
    position: sticky;
    top: -1px;
    z-index: 2;
    &.cb-sticky-active {
      box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
    }
    .cb-table-sort:first-of-type {
      box-shadow: inset 1px 0px 0px $table-border-color;
      border-left: 0 !important;
    }
  }

  // sticky column relative to parent
  // table tag
  &.cb-sticky-table-column {
    > thead {
      th {
        background-color: $gray1;
        // TBD
        border: 0;
        box-shadow: inset -1px 0px 0px $table-border-color, inset 0.1px 0px 0px $table-border-color;
        &:first-of-type {
          box-shadow: inset -1px 0px 0px $white;
          border-right: 0 !important;
          + th {
            border-left: 0 !important;
          }
        }
      }
    }
    .cb-sticky-column {
      position: sticky;
      left: 0px;
      z-index: 1;
      background-color: $white;
      border-left: 0 !important;
      border-right: 0 !important;
      box-shadow: inset -1px 0px 0px $table-border-color, inset 1px 0px 0px $table-border-color;
      + th,
      + td {
        border-left: 0 !important;
      }
    }
    .cb-stripe {
      .cb-sticky-column {
        background-color: $gray5 !important;
      }
    }
    &.cb-no-border {
      .cb-sticky-column {
        box-shadow: inset -1px 0px 0px $table-border-color !important;
      }
    }
    > thead {
      th,
      .cb-table-sort {
        &:first-child {
          position: sticky;
          left: 0px;
          z-index: 2;
        }
      }
    }
  }
}
// FF Only
@-moz-document url-prefix() {
  .cb-table-react {
    .cb-sticky-table-column {
      .cb-sticky-column {
        box-shadow: inset 0px -0.7px 1px $table-border-color, inset -1px 0px 0px $table-border-color !important;
      }
    }
  }
}
