// -----------------------------
// CB Icons
// -----------------------------

@use "sass:math";
@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/glyph.scss";
@import "../mixins/breakpoint.scss";

@include font-face($iconsFontFamily, $iconsFile, null, normal, woff ttf);

.cb-icon {
  @include glyph-font($iconsFontFamily);
}

// ------------------------------------ ICONS
.cb-icon {
  @each $key, $value in $icons {
    &.cb-#{$key} {
      @include glyph-content($value);
    }
  }

  &.cb-search {
    background: none;
  }
}

// ------------------------------------ NUMERIC
.cb-icon-numeric {
  @include border-box();
  display: inline-block;
  border-radius: 50%;
  font-size: 1rem;
  width: 2em;
  height: 2em;
  font-weight: 700;
  line-height: 1.82em !important;
  text-align: center;
  border: 0.125em solid $font-color-base;

  &::before {
    content: attr(data-cb-number);
  }

  &.cb-icon-circular {
    color: $white;
    border-color: $blue5;
    background-color: $blue5;
  }
}

// ------------------------------------ CIRCULAR
.cb-icon-circular {
  &:not(.cb-icon-numeric) {
    position: relative;
    display: block;
    height: 2em;
    width: 2em;

    &::before {
      position: absolute;
      z-index: 2;
      left: math.div(1em, 2);
      top: math.div(1em, 2);
      color: $white;
    }

    &::after {
      content: " ";
      position: absolute;
      z-index: 1;
      display: block;
      border-radius: 50%;
      height: 2em;
      width: 2em;
      background-color: $blue5;
    }
  }
}

// ------------------------------------ AVATAR
.cb-icon-avatar {
  transition: all $transition-time-l ease;
  position: relative;
  display: block;
  font-size: 1rem;
  width: 2em;
  height: 2em;

  &::before {
    position: absolute;
    z-index: 2;
    font-size: 1.3125em;
    left: math.div(0.5em, 2);
    top: math.div(0.5em, 2);
    color: $white;
  }

  &::after {
    content: " ";
    position: absolute;
    z-index: 1;
    display: block;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    background-color: $black1;
  }

  // md up
  @include media-breakpoint-up("md", $grid-breakpoints) {
    width: 3em;
    height: 3em;

    &::before {
      font-size: 2em;
    }

    &::after {
      width: 3em;
      height: 3em;
    }
  }
}

// ------------------------------------ NEW SIZE PATTERN


@each $size in $iconSize {
  .cb-icon {
    &.cb-icon-#{$size} {
      font-size: #{$size}px;
    }

    &.cb-icon-circular {
      $name: ($size * 2);

      &.cb-icon-#{$name} {
        font-size: #{$size}px;
      }
    }
  }
}
// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $iconSize {
      .cb-icon-#{$breakpoint}-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $iconSize {
      .cb-icon-#{$breakpoint}-up-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $iconSize {
      .cb-icon-#{$breakpoint}-down-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$numIconSizeNew: (12, 14, 16, 24, 32);
@each $size in $numIconSizeNew {
  .cb-icon-numeric {
    $name: ($size * 2);

    &.cb-icon-#{$name} {
      font-size: #{$size}px;
      flex-shrink: 0;
    }

    &.cb-icon-circular {
      $name: ($size * 2);

      &.cb-icon-#{$name} {
        font-size: #{$size}px;
        flex-shrink: 0;
      }
    }

    &.cb-icon-24 {
      line-height: 23px !important;
    }
  }
}
// Numeric Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $numIconSizeNew {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $numIconSizeNew {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-up-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $numIconSizeNew {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-down-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

// ------------------------------------ COLOR

@each $color, $map in $colorThemes {
  .cb-icon {
    &.#{"cb-" + $color + "-color"} {
      &.cb-icon-circular {
        &::after {
          background-color: map-get($map, code);
        }
      }
    }
  }

  .cb-icon-numeric {
    &.#{"cb-" + $color + "-color"} {
      border-color: map-get($map, code);

      &.cb-icon-circular {
        color: $white !important;
        border-color: map-get($map, code);
        background-color: map-get($map, code);
      }
    }
  }
}

@media print {
  @include print-icon-numeric();
}

.cb-print {
  @include print-icon-numeric();
}

// ------------------------------------ CUSTOM
.cb-play-video {
  font-size: 4rem;
}
