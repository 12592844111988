// -----------------------------
// CB CUSTOM CSS
// -----------------------------

@import "apricot-variable.scss";
// ------------------------------------ Custom Styles for Projects
$tabs-height: 32px;

.k12cloudPortal-toggles-btn {
  height: $tabs-height;
  .cb-toggles-btn {
    label {
      width: 120px;
      height: $tabs-height;
      input[type="radio"] {
        + span {
          width: 120px !important;
          height: $tabs-height !important;
          font-weight: 400 !important;
        }
        &:checked {
          & + span {
            background-color: $gray1 !important;
            color: $white !important;
            position: relative;
            padding-left: 8px;
            &::before {
              font-family: $iconsFontFamily;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              display: inline-block;
              position: absolute;
              content: "\e953";
              font-size: 14px;
              color: $white;
              left: 8px;
            }
          }
        }
      }
      &:first-of-type input[type="radio"] + span:not(.cb-glyph):not(.cb-icon) {
        border-bottom-left-radius: 0;
      }
      &:last-of-type input[type="radio"] + span:not(.cb-glyph):not(.cb-icon) {
        border-bottom-right-radius: 0;
      }
    }
  }
}


// ------- Keyboard focus
[data-cb-input-m="keyboard"] {
  .k12cloudPortal-toggles-btn {
    .cb-toggles-btn {
      input[type="radio"] {
        &:focus {
          & + span:not(.cb-glyph):not(.cb-icon) {
            border-left: 1px solid $white;
            position: relative;
            box-shadow: none;
            &::after {
              content: "";
              border: 2px solid $blue5;
              position: absolute;
              height: calc(100% + 3px);
              width: calc(100% + 4px);
              top: -2px;
              right: -1px;
              box-shadow: inset 0 0 0px 1px $white;
            }
          }
        }
      }

      label {
        &:first-of-type {
          input[type="radio"] {
            &:focus {
              & + span:not(.cb-glyph):not(.cb-icon) {
                &::after {
                  border-top-left-radius: 8px;
                }
              }
            }
          }
        }
        &:last-of-type {
          input[type="radio"] {
            &:focus {
              & + span:not(.cb-glyph):not(.cb-icon) {
                &::after {
                  border-top-right-radius: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}