// -----------------------------
// CB Menu Item
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/menu-list.scss";
@import "../mixins/link.scss";

a {
  &.cb-menu-link {
    // black
    @include black-link();
    font-size: 0.875rem !important;
    line-height: 16px;
    text-decoration: none !important;
    transition: color $transition-time-s ease-in-out;
    transition: background-color $transition-time-s ease-in-out;
    text-align: center;

    &:hover,
    &.cb-hover {
      background-color: $gray5;
    }

    &:focus,
    &.cb-focus {
      outline-color: $outlineColor;
    }
  }

  &.cb-menu-link-horizontal {
    @include horizontal-menu-link();
  }

  &.cb-menu-link-vertical {
    @include vertical-menu-link();
    text-align: left;
  }
}

[data-cb-input-m="keyboard"] {
  a {
    &.cb-menu-link {
      &:focus,
      &.cb-focus {
        text-decoration: underline !important;
      }
    }
  }
}

.cb-menu-list {
  display: flex;

  ul,
  div[role="list"] {
    margin: 0;
    padding: 0;
    list-style: none;

    li,
    div[role="listitem"] {
      list-style: none !important;

      ul,
      div[role="list"] {
        li,
        div[role="listitem"] {
          list-style: none !important;

          &:before {
            content: "";
          }
        }
      }
    }
  }

  &.cb-menu-list-horizontal {
    height: 100%;

    ul,
    div[role="list"] {
      display: flex;
      height: 100%;

      li,
      div[role="listitem"] {
        a {
          @include horizontal-menu-link(0);
        }
      }
    }
    &.cb-condensed {
      ul,
      div[role="list"] {
        display: flex;
        height: 100%;

        li,
        div[role="listitem"] {
          a {
            @include horizontal-menu-link(1);
          }
        }
      }
    }
  }

  &.cb-menu-list-vertical {
    ul,
    div[role="list"] {
      width: 100%;

      li,
      div[role="listitem"] {
        width: 100%;

        a {
          @include vertical-menu-link();
          text-align: left;
          &.cb-selected {
            font-weight: 700;
          }
        }

        &.cb-menu-list-heading {
          padding: 16px 24px 16px 24px;
        }
      }
    }

    &.cb-menu-list-condensed {
      ul,
      div[role="list"] {
        li,
        div[role="listitem"] {
          a {
            padding: 12px 24px 12px 24px;
          }

          &.cb-menu-list-heading {
            padding: 12px 24px 12px 24px;
          }
        }
      }
    }

    &.cb-menu-list-tall {
      ul,
      div[role="list"] {
        li,
        div[role="listitem"] {
          a {
            padding: 24px;
            // line-height: 24px;
            font-size: 1rem !important;
            line-height: 1.5em;
          }

          &.cb-menu-list-heading {
            padding: 24px;
            line-height: 24px;
          }
        }
      }
    }

    &.cb-menu-list-glyph {
      ul,
      div[role="list"] {
        li:not(.cb-menu-list-heading-link),
        div[role="listitem"]:not(.cb-menu-list-heading-link) {
          a {
            display: flex;
            justify-content: space-between;

            .cb-glyph,
            .cb-icon {
              margin-left: 24px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    &.cb-menu-list-separated {
      ul,
      div[role="list"] {
        li,
        div[role="listitem"] {
          &:first-of-type {
            a {
              border-top: 1px solid $border-color;
            }
          }

          &.cb-menu-list-heading-link {
            a {
              border-top: 0;
            }
          }

          + li,
          + div[role="listitem"] {
            a {
              border-top: 1px solid $border-color;
            }
          }
        }
      }
    }
    &.cb-menu-list-accordion {
      div[role="list"] {
        display: flex;
        flex-direction: column;
        .cb-accordion-trigger-panel {
          &.cb-in {
            border-top: 0;
            border-bottom: 0;
            margin-bottom: 0;
          }
          .cb-accordion-panel-content {
            padding: 0;
          }
        }
        .cb-accordion-trigger {
          margin-top: 24px;
          .cb-accordion-icon {
            display: none;
          }
          &.cb-align-right {
            margin-left: auto;
            padding-right: 24px;
          }
          &.cb-align-left {
            padding-left: 24px;
            max-width: fit-content;
          }
        }
      }
    }
  }
}

// When we want the style without a list
.cb-menu-single {
  display: flex;

  &.cb-menu-list-vertical {
    a {
      @include vertical-menu-link();
      text-align: left;
    }
    .cb-menu-list-heading {
      padding: 16px 24px 16px 24px;
    }

    &.cb-menu-list-condensed {
      a {
        padding: 12px 24px 12px 24px;
      }
      .cb-menu-list-heading {
        padding: 12px 24px 12px 24px;
      }
    }

    &.cb-menu-list-tall {
      a {
        padding: 24px;
        font-size: 1rem !important;
        line-height: 1.5em;
      }
      .cb-menu-list-heading {
        padding: 24px;
        line-height: 24px;
      }
    }

    &.cb-menu-list-glyph {
      a {
        display: flex;
        justify-content: space-between;

        .cb-glyph,
        .cb-icon {
          margin-left: 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
